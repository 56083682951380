<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon [icIcon]="icPerson"></mat-icon>
      </div>
      <div class="dropdown-heading">{{name}}</div>
    </div>
    <!-- 
    <button [matMenuTriggerFor]="settingsMenu"
            mat-icon-button
            matTooltip="Settings"
            matTooltipPosition="before"
            type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button> -->
  </div>

  <div class="dropdown-content">
    <a (click)="openVersion()" class="notification" fxLayout="row" fxLayoutAlign="start center" matRipple>
      <mat-icon [icIcon]="icListAlt" ngClass="text-teal" class="notification-icon" fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">Versiones de la aplicación</div>
        <div class="notification-description">Solo usuarios de desarrollo</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <button [matMenuTriggerFor]="statusMenu" class="dropdown-footer-select" mat-button type="button">
      <ng-container *ngFor="let status of statuses; trackBy: trackById">
        <span *ngIf="status === activeStatus">
          <mat-icon [icIcon]="status.icon" [ngClass]="status.colorClass"></mat-icon>
          <span>{{ status.label }}</span>
          <mat-icon [icIcon]="icArrowDropDown" class="dropdown-footer-select-caret"></mat-icon>
        </span>
      </ng-container>
    </button>
    <a (click)="close()" color="primary" mat-button>LOGOUT</a>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="setStatus(status)" *ngFor="let status of statuses; trackBy: trackById" mat-menu-item>
    <ic-icon [icon]="status.icon" [ngClass]="status.colorClass" inline="true" size="24px"></ic-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>