import { Component, OnInit, Inject } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SerService } from 'src/app/services/ser.service';
import { ActivatedRoute, ParamMap } from '@angular/router'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';

@Component({
  selector: 'vex-pedido-detail',
  templateUrl: './pedido-detail.component.html',
  styleUrls: ['./pedido-detail.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class PedidoDetailComponent implements OnInit {
  id: number

  markers = [];
  latRes: any;
  lngRes: any;

  latC: any;
  lngC: any;
  icon: string;
  iconRes: string;
  zoom: number = 10;
  // initial center position for the map
  lat: number = -17.451312839438536;
  lng: number = -66.12951627803223;


  displayedColumns: string[] = ['position', 'precio', 'name'];
  dataSource = [];
  ext: any;
  delivery: any;
  total_pedido: any;
  general_total: any;
  bodyMessageCliente: any;
  bodyMessageRes: any;
  idPedido: any;
  data = [{nombre:"",apellido:"",total:"",cliente_numero:""}];
  clienteName: any;
  clienteApellido: any;
  clienteCelular: any;
  clienteDireccion: any;
  resName: any;
  resCelular: any;
  resDireccion: any;
  constructor(
    private route: ActivatedRoute,
  private service:SerService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = +params.get('id')
      console.log(this.id);
    this.service.getPedidoOrderTracking(this.id).subscribe(data => {
      this.data = data.data;
      console.log("servicio funcionando",data);
      //clientes
      this.clienteName = data.data.cliente_nombres;
      this.clienteApellido = data.data.cliente_apellidos;
      this.clienteCelular = data.data.cliente_numero;
      this.clienteDireccion = data.data.direccion_entrega;
      //restaurante 
      this.resName = data.data.restaurante_nombre;
      this.resCelular = data.data.restaurante_celular;
      this.resDireccion = data.data.direccion_entrega;


      this.idPedido = data.data.id;
      this.latRes = data.data.restaurante_lat;
      this.lngRes = data.data.restaurante_lng;
      this.latC = data.data.lat_pedido;
      this.lngC = data.data.lng_pedido;
      this.icon = './assets/cliente.png';
      this.iconRes = './assets/res.png';
      this.delivery = parseInt(data.data.costo_envio);
      this.total_pedido = parseInt(data.data.total);
      this.general_total = this.delivery + this.total_pedido;
      // this.markers.push({
      //   lat: this.defaults.restaurante_lat,
      //   lng: this.defaults.restaurante_lng,
      //   draggable: false,
      // });

      this.dataSource = data.data.detalle;
      console.log(this.dataSource);
      this.bodyMessageCliente = `Hola ${data.data.cliente_nombres}\n\n ${data.data.cliente_apellidos}\n deseas confirmar tu pedido para realizar tu entrega a domicilio.?`;
      this.bodyMessageRes = `Hola ${data.data.restaurante_nombre}\n tienes un pedido pendiente por favor revisa tu App para aceptar o rechazar el pedido?`;

    }, err => {
      console.log(err);
     
    });
   
    })
  
  

  }


  sendWhatshappClientt() {
    //  this.ext = '';
    //     this.defaults.detalle.forEach(element => {
    //       console.log(element);
    //       this.ext +=  ` ${element.plato + ' ' + element.cantidad},\n\n   `
    //       // this.dataSource.push(element);
    //   });
    console.log(this.ext);
    // let ext
    // const bodyMessage = `Hello ${}\n\nI'm here to tell you your child's routine on ${date}.\n\nHis/her entry was at ${entry} and his/her exit at ${exit}.\n\nI will tell you how the student's meals were on the day! The student's meals occurred as follows:\ncollation: ${collation}\nlunch: ${lunch}\nsnack: ${snack}\ndinner: ${dinner}\n\n${descriptionRemedy}`;
    // const bodyMessage = `Hola ${this.defaults.cliente_nombres}\n\n ${this.defaults.cliente_apellidos}\n deseas confirmar tu pedido para realizar tu entrega a domicilio.?`;
    // return bodyMessage;
  }
  sendWhatshappRes() {
    //  this.ext = '';
    //     this.defaults.detalle.forEach(element => {
    //       console.log(element);
    //       this.ext +=  ` ${element.plato + ' ' + element.cantidad},\n\n   `
    //       // this.dataSource.push(element);
    //   });
    // console.log(this.ext);
    // // let ext
    // // const bodyMessage = `Hello ${}\n\nI'm here to tell you your child's routine on ${date}.\n\nHis/her entry was at ${entry} and his/her exit at ${exit}.\n\nI will tell you how the student's meals were on the day! The student's meals occurred as follows:\ncollation: ${collation}\nlunch: ${lunch}\nsnack: ${snack}\ndinner: ${dinner}\n\n${descriptionRemedy}`;
    // const bodyMessage = `Hola ${this.defaults.restaurante_nombre}\n tienes un pedido pendiente por favor revisa tu App para aceptar o rechazar el pedido?`;
    // return bodyMessage;
  }

  // openDialog(){
  //   const dialogRef = this.dialog.open(ModificarPriceComponent, {
  //     data: {precio:this.delivery,idPedido: this.idPedido
  //   }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed', result);
  //     if (result) {
  //       this.ngOnInit();
  //     }
  //   });
  // }


}