import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { EncrDecrService } from '../services/encr-decr-service.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private EncrDecr: EncrDecrService,) { }

  rol = '';
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (localStorage.getItem('keylog')) {
      var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem('keylog'));
      this.rol = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem('log'));

      if (isNaN(decrypted) != false) {
        localStorage.clear();
      } else {
        if (localStorage.getItem('token')) {
          let roles = next.data["roles"];
          if (this.rol == roles) {
            if (this.rol == 'Administrador') {
              if (state.url == '/apps/users') {
                return true;
              } else if (state.url == '/apps/clientes') {
                return true;
              } else if (state.url == '/apps/repartidores') {
                return true;
              } else if (state.url == '/apps/costos') {
                return true;
              } else if (state.url == '/apps/pedidos') {
                return true;
              } else if (state.url == '/apps/restaurantes') {
                return true;
              } else if (state.url == '/apps/reportes') {
                return true;
              } else if (state.url == '/apps/categorias') {
                return true;
              }
            }
          } else if (roles == 'All') {
            return true;
          } else if (roles != this.rol) {
            localStorage.clear();
            this.router.navigate(['/login']);
            return false;
          }
        } else {
          this.router.navigate(['/login']);
          localStorage.clear();
          return false;
        }
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }
}
