<form *ngIf="modeStyle == ''" [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

    <h2 *ngIf="form.get('name').value" class="headline m-0" fxFlex="auto">{{
      form.get('name').value }}</h2>
    <h2 *ngIf="!form.get('name').value" class="headline m-0" fxFlex="auto">
      Registrar Usuario</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content fxLayout="column">
    <div class="flex flex-col sm:flex-row">
      <mat-form-field appearance="outline" class="mt-6 flex-auto">
        <mat-label>Nombre de Usuario</mat-label>
        <input cdkFocusInitial formControlName="name" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label>Tipo</mat-label>
        <mat-select formControlName="tipo">
          <mat-option value="Administrador">Administrador</mat-option>
          <mat-option value="Recepcion">Recepción</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <mat-label class="flex-auto" style="color: white;">Accesos</mat-label>

    <div class="flex flex-col sm:flex-row">
      <mat-form-field appearance="outline" class="mt-6 flex-auto">
        <mat-label>Contraseña</mat-label>
        <input [type]="inputType" autocomplete="new-password" cdkFocusInitial formControlName="password" matInput>
        <button (click)="togglePassword()" mat-icon-button matSuffix type="button">
          <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
          <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label>R. Contraseña</mat-label>
        <input [type]="inputType" autocomplete="new-password" cdkFocusInitial formControlName="password_confirmation"
          matInput>
        <button (click)="togglePassword()" mat-icon-button matSuffix type="button">
          <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
          <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
        </button>
      </mat-form-field>
    </div>


  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCELAR</button>
    <button *ngIf="isCreateMode()" class="btn-block" adiButton [loading]="loading[2]" (click)="add(2)" cdkFocusInitial>
      Guardar
      <ng-template adiButtonLoader> Guardando... </ng-template>
    </button>
    <button *ngIf="isUpdateMode()" class="btn-block" adiButton [loading]="loading[2]" (click)="edit(2)" cdkFocusInitial>
      Guardar
      <ng-template adiButtonLoader> Guardando... </ng-template>
    </button>
  </mat-dialog-actions>
</form>

<div *ngIf="modeStyle == 'loginApp'">
  <form [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

      <h2 class="headline m-0" fxFlex="auto">Iniciar sesión como desarrollador de aplicaciones</h2>

      <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">
      <div class="flex flex-col sm:flex-row">
        <mat-form-field appearance="outline" class="mt-6 flex-auto">
          <mat-label>Contraseña</mat-label>
          <input [type]="inputType" autocomplete="new-password" cdkFocusInitial formControlName="password" matInput>
          <button (click)="togglePassword()" mat-icon-button matSuffix type="button">
            <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
            <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row">
        <mat-form-field appearance="outline" class="mt-6 flex-auto">
          <mat-label>Seleccionar aplicación</mat-label>
          <mat-select formControlName="aplicacion">
            <mat-option value="cliente">Cliente</mat-option>
            <mat-option value="repartidor">Repartidor</mat-option>
            <mat-option value="restaurante">Restaurante</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button *ngIf="form.valid" mat-button type="button" (click)="initVersio(form.value.aplicacion)" cdkFocusInitial>
        INGRESAR
      </button>
    </mat-dialog-actions>
  </form>
</div>

<div *ngIf="modeStyle == 'general'">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

    <h2 class="headline m-0" fxFlex="auto">Versiones de la aplicación</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content fxLayout="column">
    <br>
    <button mat-raised-button *ngIf="!isLoadingResults && defaults.mode == 'cliente'" (click)="createBcliente()"
      color="primary" style="margin-bottom: 15px;">Publicar
      Versión</button>
    <button mat-raised-button *ngIf="!isLoadingResults && defaults.mode == 'repartidor'" (click)="createBrepartidor()"
      color="primary" style="margin-bottom: 15px;">Publicar
      Versión</button>
    <button mat-raised-button *ngIf="!isLoadingResults && defaults.mode == 'restaurante'" (click)="createBrestaurante()"
      color="primary" style="margin-bottom: 15px;">Publicar
      Versión</button>
    <mat-spinner class="loader" *ngIf="isLoadingResults"></mat-spinner>
    <div *ngIf="vers">
      <!-- *ngFor="let item of vers; index as i"> -->
      <mat-card class="example-card" style="margin-bottom: 15px; margin-top: 15px;">
        
        <mat-card-header>
          <mat-card-title>Versión ID #{{vers.id}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Versión Android: {{vers.nombre}}
          </p>
          <p>
            Versión Ios: {{vers.nombre_ios}}
          </p>
          <p>
            Fecha de publicación: {{vers.fecha_subida}}
          </p>
          <p>
            Hora de publicación: {{vers.hora_subida}}
          </p>
        </mat-card-content>
        <!-- <mat-card-actions>
          <button mat-button *ngIf="i == 0 && defaults.mode == 'cliente'" (click)="editBcliente(item)">
            Editar
          </button>
          <button mat-button *ngIf="i == 0 && defaults.mode == 'repartidor'" (click)="editBrepartidor(item)">
            Editar
          </button>
          <button mat-button *ngIf="i == 0 && defaults.mode == 'restaurante'" (click)="editBrestaurante(item)">
            Editar
          </button>
        </mat-card-actions> -->
      </mat-card>
    </div>

  </mat-dialog-content>
</div>

<div *ngIf="modeStyle == 'createVersion'">
  <form [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

      <h2 *ngIf="form.get('nombre').value" class="headline m-0" fxFlex="auto">{{
        form.get('nombre').value }}</h2>
      <h2 *ngIf="!form.get('nombre').value" class="headline m-0" fxFlex="auto">
        Registrar versión</h2>

      <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">
      <div class="flex flex-col sm:flex-row">
        <mat-form-field appearance="outline" class="mt-6 flex-auto">
          <mat-label>Nombre version Android</mat-label>
          <input cdkFocusInitial formControlName="nombre" matInput>
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline" class="mt-6 flex-auto">
          <mat-label>Nombre version Ios</mat-label>
          <input cdkFocusInitial formControlName="nombre_ios" matInput>
        </mat-form-field>

      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close type="button">CANCELAR</button>
      <button *ngIf="app == 'cliente' && defaults.mode == 'createVersion'" class="btn-block" adiButton
        [loading]="loading[2]" (click)="addVersionCliente(2)" cdkFocusInitial>
        Guardar
        <ng-template adiButtonLoader> Guardando... </ng-template>
      </button>
      <button *ngIf="app == 'repartidor' && defaults.mode == 'createVersion'" class="btn-block" adiButton
        [loading]="loading[2]" (click)="addVersionRepartidor(2)" cdkFocusInitial>
        Guardar
        <ng-template adiButtonLoader> Guardando... </ng-template>
      </button>
      <button *ngIf="app == 'restaurante' && defaults.mode == 'createVersion'" class="btn-block" adiButton
        [loading]="loading[2]" (click)="addVersionRestaurante(2)" cdkFocusInitial>
        Guardar
        <ng-template adiButtonLoader> Guardando... </ng-template>
      </button>

      <button *ngIf="app == 'cliente' && defaults.mode == 'editVersion'" class="btn-block" adiButton
        [loading]="loading[2]" (click)="editVersionCliente(2)" cdkFocusInitial>
        Guardar
        <ng-template adiButtonLoader> Guardando... </ng-template>
      </button>
      <button *ngIf="app == 'repartidor' && defaults.mode == 'editVersion'" class="btn-block" adiButton
        [loading]="loading[2]" (click)="editVersionRepartidor(2)" cdkFocusInitial>
        Guardar
        <ng-template adiButtonLoader> Guardando... </ng-template>
      </button>
      <button *ngIf="app == 'restaurante' && defaults.mode == 'editVersion'" class="btn-block" adiButton
        [loading]="loading[2]" (click)="editVersionRestaurante(2)" cdkFocusInitial>
        Guardar
        <ng-template adiButtonLoader> Guardando... </ng-template>
      </button>
    </mat-dialog-actions>
  </form>
</div>