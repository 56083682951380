import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { User } from '../interfaces/user.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SerService } from '../../../../services/ser.service';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icVisibility from '@iconify/icons-ic/twotone-visibility';

@Component({
  selector: 'vex-customer-create-update',
  templateUrl: './customer-create-update.component.html',
  styleUrls: ['./customer-create-update.component.scss']
})
export class CustomerCreateUpdateComponent implements OnInit {

  loading = { 1: false, 2: false, 3: false, 4: false };

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;
  modeStyle = '';
  vers: any;
  isLoadingResults = true;
  app: any;
  data:any;
  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<CustomerCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    public service: SerService,
    private cd: ChangeDetectorRef) {
      console.log(this.defaults);
      
  }

  ngOnInit() {
    if (this.defaults) {
      this.mode = 'update';
      this.modeStyle = '';
      this.form = this.fb.group({
        id: [this.defaults.id],
        name: [this.defaults.name, [Validators.required]],
        tipo: [this.defaults.tipo, [Validators.required]],
        password: ['', [Validators.minLength(6)]],
        password_confirmation: ['', [Validators.minLength(6)]],
      });
    } else {
      this.defaults = {} as User;
      this.modeStyle = '';
      this.form = this.fb.group({
        name: ['', [Validators.required]],
        tipo: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        password_confirmation: ['', [Validators.required, Validators.minLength(6)]],
      });
    }

    if (this.defaults) {
      if (this.defaults.mode) {
        if (this.defaults.mode == 'loginApp') {
          this.modeStyle = 'loginApp';
          this.form = this.fb.group({
            aplicacion: ['', [Validators.required]],
            password: ['', [Validators.required]],
          });
        } else if (this.defaults.mode == 'cliente') {
          this.modeStyle = 'general';
          this.versiones(this.service.cliente());
        } else if (this.defaults.mode == 'repartidor') {
          this.modeStyle = 'general';
          this.versiones(this.service.repartidor());
        } else if (this.defaults.mode == 'restaurante') {
          this.modeStyle = 'general';
          this.versiones(this.service.restaurante());
        } else if (this.defaults.mode == 'createVersion') {
          this.form = this.fb.group({
            nombre: ['', [Validators.required]],
            nombre_ios: ['', [Validators.required]],

          });
          this.modeStyle = 'createVersion';
          this.app = this.defaults.data.mode;
        } else if (this.defaults.mode == 'editVersion') {
          this.form = this.fb.group({
            nombre: [this.defaults.edit.nombre, [Validators.required]],
            nombre_ios: [this.defaults.edit.nombre_ios, [Validators.required]],

          });
          this.modeStyle = 'createVersion';
          this.app = this.defaults.data.mode;
        }
      }

    }
  }

  add(i) {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.createUsers(this.form.value.name, this.form.value.password,
        this.form.value.password_confirmation, this.form.value.tipo).subscribe((data) => {
          this.message('Usuario creado');
          console.log(data);
          const customer = this.form.value;
          this.dialogRef.close(customer);
        }, err => {
          this.loading[i] = false;
          if (err.error.errors.password) {
            if (err.error.errors.password[0] == 'The password confirmation does not match.') {
              this.message('Las contraseñas no coinciden');
              console.log(err);
            }
          } else {
            if (err.error.errors.name) {
              if (err.error.errors.name[0] == 'The name has already been taken.') {
                this.message('Ya está registrado este nombre');
                console.log(err);
              }
            } else {
              this.message('Error New');
              console.log(err);
            }
          }
        });
    } else {
      this.loading[i] = false;
      this.message('Completa los campos');
    }
  }


  edit(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.editUsers(this.defaults.id, this.form.value.name,
        this.form.value.password, this.form.value.password_confirmation, this.form.value.tipo).subscribe((data) => {
          this.message('Usuario Editado');
          console.log(data);
          const customer = this.form.value;
          customer.id = this.defaults.id;
          this.dialogRef.close(customer);
        }, err => {
          this.loading[i] = false;
          if (err.error.error) {
            if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
              this.message('Se debe indicar al menos un valor diferente para actualizar');
              console.log(err);
            }
          } else if (err.error.errors.password) {
            if (err.error.errors.password[0] == 'The password confirmation does not match.') {
              this.message('Las contraseñas no coinciden');
            }
          } else if (err.error.errors.name) {
            if (err.error.errors.name[0] == 'The name has already been taken.') {
              this.message('Ya está registrado este nombre');
              console.log(err);
            }
          } else {
            this.message('Error New');
            console.log(err);
          }
        });
    } else {
      this.loading[i] = false;
      this.message('Completa los campos');
    }
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  message(m): void {
    this.snackbar.open(m, 'Cerrar', {
      duration: 4000
    });
  }

  visible = false;
  inputType = 'password';

  togglePassword() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  initVersio(mode): void {
    console.log(mode);
    
    if (this.form.valid) {
      if (this.form.value.password == 'adminappmovil') {
        this.dialogRef.close();
        this.dialog.open(CustomerCreateUpdateComponent, {
          width: '600px',
          data: { mode: mode },
        });
      } else {
        this.message('Accesos no permitido')
        this.dialogRef.close();
      }
    } else {
      this.message('Completa los campos');
    }
  }

  versiones(version): void {
    console.log(version);
    
    this.isLoadingResults = true;
    this.vers = [];
    version.subscribe((data) => {
      this.vers = data.data;
      console.log(this.vers);
      this.isLoadingResults = false;
    });
  }

  createBcliente(): void {
    this.dialog.open(CustomerCreateUpdateComponent, {
      width: '600px',
      data: { data: this.defaults, mode: 'createVersion' },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.versiones(this.service.cliente());
      }
    });
  }

  createBrepartidor(): void {
    this.dialog.open(CustomerCreateUpdateComponent, {
      width: '600px',
      data: { data: this.defaults, mode: 'createVersion' },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.versiones(this.service.repartidor());
      }
    });
  }

  createBrestaurante(): void {
    this.dialog.open(CustomerCreateUpdateComponent, {
      width: '600px',
      data: { data: this.defaults, mode: 'createVersion' },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.versiones(this.service.restaurante());
      }
    });
  }

  addVersionCliente(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.createClienteVer(this.form.value.nombre,this.form.value.nombre_ios).subscribe((data) => {
        this.message('Versión creada');
        console.log(data);
        const customer = this.form.value;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.errors.nombre) {
          if (err.error.errors.nombre[0] == 'The nombre has already been taken.') {
            this.message('Ya se registro esta versión');
            console.log(err);
          }
        } else {
          this.message('Error New');
          console.log(err);
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Completa los campos');
    }
  }

  addVersionRepartidor(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.createRepVer(this.form.value.nombre,this.form.value.nombre_ios).subscribe((data) => {
        this.message('Versión creada');
        console.log(data);
        const customer = this.form.value;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.errors.nombre) {
          if (err.error.errors.nombre[0] == 'The nombre has already been taken.') {
            this.message('Ya se registro esta versión');
            console.log(err);
          }
        } else {
          this.message('Error New');
          console.log(err);
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Completa los campos');
    }
  }

  addVersionRestaurante(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.createRestVer(this.form.value.nombre,this.form.value.nombre_ios).subscribe((data) => {
        this.message('Versión creada');
        console.log(data);
        const customer = this.form.value;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.errors.nombre) {
          if (err.error.errors.nombre[0] == 'The nombre has already been taken.') {
            this.message('Ya se registro esta versión');
            console.log(err);
          }
        } else {
          this.message('Error New');
          console.log(err);
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Completa los campos');
    }
  }

  editBcliente(item): void {
    this.dialog.open(CustomerCreateUpdateComponent, {
      width: '600px',
      data: { data: this.defaults, mode: 'editVersion', edit: item },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.versiones(this.service.cliente());
      }
    });
  }

  editBrepartidor(item): void {
    this.dialog.open(CustomerCreateUpdateComponent, {
      width: '600px',
      data: { data: this.defaults, mode: 'editVersion', edit: item },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.versiones(this.service.repartidor());
      }
    });
  }

  editBrestaurante(item): void {
    this.dialog.open(CustomerCreateUpdateComponent, {
      width: '600px',
      data: { data: this.defaults, mode: 'editVersion', edit: item },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.versiones(this.service.restaurante());
      }
    });
  }

  editVersionCliente(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.editClienteVer(this.defaults.edit.id, this.form.value.nombre).subscribe((data) => {
        this.message('Versión Editada');
        const customer = this.form.value;
        customer.id = this.defaults.id;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.error) {
          if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
            this.message('Se debe indicar al menos un valor diferente para actualizar');
            console.log(err);
          }
        } else if (err.error.errors.nombre) {
          if (err.error.errors.nombre[0] == 'The nombre has already been taken.') {
            this.message('Ya se registro esta versión');
            console.log(err);
          }
        } else {
          this.message('Error New');
          console.log(err);
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Completa los campos');
    }
  }

  editVersionRepartidor(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.editRepVer(this.defaults.edit.id, this.form.value.nombre).subscribe((data) => {
        this.message('Versión Editada');
        const customer = this.form.value;
        customer.id = this.defaults.id;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.error) {
          if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
            this.message('Se debe indicar al menos un valor diferente para actualizar');
            console.log(err);
          }
        } else if (err.error.errors.nombre) {
          if (err.error.errors.nombre[0] == 'The nombre has already been taken.') {
            this.message('Ya se registro esta versión');
            console.log(err);
          }
        } else {
          this.message('Error New');
          console.log(err);
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Completa los campos');
    }
  }

  editVersionRestaurante(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.editRestVer(this.defaults.edit.id, this.form.value.nombre).subscribe((data) => {
        this.message('Versión Editada');
        const customer = this.form.value;
        customer.id = this.defaults.id;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.error) {
          if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
            this.message('Se debe indicar al menos un valor diferente para actualizar');
            console.log(err);
          }
        } else if (err.error.errors.nombre) {
          if (err.error.errors.nombre[0] == 'The nombre has already been taken.') {
            this.message('Ya se registro esta versión');
            console.log(err);
          }
        } else {
          this.message('Error New');
          console.log(err);
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Completa los campos');
    }
  }
}
